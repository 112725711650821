import React, { createContext, useState } from 'react';

export const MyContext = createContext();

const MyContextProvider = ({ children }) => {
    const [someValue, setSomeValue] = useState('default value');

    return (
        <MyContext.Provider value={{ someValue, setSomeValue }}>
            {children}
        </MyContext.Provider>
    );
};

export default MyContextProvider;
