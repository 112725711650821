// import React from 'react';

// const Contact = () => {
//   return (
//     <div>
//       <h1>Contact Us</h1>
//       <p>Email: admin@peritose.com</p>
//       <p>Phone: +91 957 330 5969 </p>
//     </div>
//   );
// };

// export default Contact;
// Contact.js
// import React, { useState } from 'react';
// import './Contact.css';

// const Contact = () => {
//   const [formState, setFormState] = useState({
//     name: '',
//     email: '',
//     message: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormState({
//       ...formState,
//       [name]: value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here
//     console.log('Form submitted:', formState);
//   };

//   return (
//     <div className="contact-container">
//       <h1>Contact Us</h1>
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label htmlFor="name">Name</label>
//           <input
//             type="text"
//             id="name"
//             name="name"
//             value={formState.name}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="email">Email</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             value={formState.email}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="message">Message</label>
//           <textarea
//             id="message"
//             name="message"
//             value={formState.message}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// };

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      to_name: 'Admin',  // Adjust as necessary
      from_name: formState.name,
      from_email: formState.email,  // If needed in your email template
      message: formState.message,
    };

    const serviceID = 'service_ds8cffd';
    const templateID = 'template_sl8jj1b';
    const publicKey = 'kamMtptEh3wrm0rDM';

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log('Success:', response.status, response.text);
        setIsSent(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder='Enter your name'
            value={formState.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='Enter your email'
            value={formState.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder='Enter your message'
            value={formState.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {isSent && (
        <div className="popup">
          <div className="popup-content">
            <div className="checkmark-circle">
              <div className="background"></div>
              <div className="checkmark draw"></div>
            </div>
            <h2>Success!</h2>
            <p>Message sent successfully!</p>
            <button onClick={() => setIsSent(false)}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;


// import React, { useState } from 'react';
// import emailjs from 'emailjs-com';
// import './Contact.css';

// const Contact = () => {
//   const [formState, setFormState] = useState({
//     name: '',
//     email: '',
//     message: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormState({
//       ...formState,
//       [name]: value
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const templateParams = {
//       to_name: 'Admin',  // Adjust as necessary
//       from_name: formState.name,
//       from_email: formState.email,  // If needed in your email template
//       message: formState.message,
//     };

//     const serviceID = 'service_ds8cffd';
//     const templateID = 'template_sl8jj1b';
//     const publicKey = 'kamMtptEh3wrm0rDM';

//     emailjs.send(serviceID, templateID, templateParams, publicKey)
//       .then((response) => {
//         console.log('Success:', response.status, response.text);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };

 
//   return (
//     <div className="contact-container">
//       <h1>Contact Us</h1>
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label htmlFor="name">Name</label>
//           <input
//             type="text"
//             id="name"
//             name="name"
//             value={formState.name}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="email">Email</label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             value={formState.email}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label htmlFor="message">Message</label>
//           <textarea
//             id="message"
//             name="message"
//             value={formState.message}
//             onChange={handleChange}
//             required
//           />
//         </div>
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// };

// export default Contact;
