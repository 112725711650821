import React from 'react';
import './Home.css';
import healthcareImage from '../assets/Innovation-1.jpg';
import rpa from '../assets/RPA-automation-what-we-do.jpg';
import ProfilCard from './ProfileCard';
import content from './Content';
import { motion } from 'framer-motion';

const Home = () => {
    return (
        <div className="home-container">
            <motion.section
                className="hero"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
            >
                <h1>Welcome to Our IT Startup</h1>
                <p>Revolutionizing Healthcare with Automation and Analytics.</p>
            </motion.section>
            <motion.section
                className="services"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
            >
                <ProfilCard
                    image={healthcareImage}
                    img_content={content.healthcare_services_content}
                />
                <ProfilCard
                    image={rpa}
                    img_content={content.rpa_content}
                />
            </motion.section>
            <footer className="contact">
                <p>Contact Us: admin@peritose.com</p>
            </footer>
        </div>
    );
};

export default Home;
