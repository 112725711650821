function ProfileCard({image, img_content}){
    return (
        <div className="column is-4">
            <img src={image} alt="Home page logo" />
            <div className="card">
            <div className="card-content">
            <div className="content">
            {img_content}
            </div>
            </div>
        </div>
        </div>
    );
}

export default ProfileCard
