import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo.png';
import './Header.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="Company Logo" />
            </div>
            <nav className={`nav ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
                    <li><Link to="/services" onClick={toggleMenu}>What We Offer</Link></li>
                    <li><Link to="/about" onClick={toggleMenu}>Who We Are</Link></li>
                    <li><Link to="/contact" onClick={toggleMenu}>Contact Us</Link></li>
                </ul>
            </nav>
            <div className="hamburger" onClick={toggleMenu}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </div>
        </header>
    );
};

export default Header;



// import React from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../assets/logo.png';
// import './Header.css';

// const Header = () => {
//   return (
//     <header>
//       <div className="logo">
//       <img src={logo} alt="Company Logo" />
//       </div>
//       <nav>
//         <ul>
//           <li><Link to="/">Home</Link></li>
//           <li><Link to="/services">What We Offer</Link></li>
//           <li><Link to="/about">Who We Are</Link></li>
//           <li><Link to="/contact">Contact Us</Link></li>
//         </ul>
//       </nav>
//     </header>
//   );
// };

// export default Header;