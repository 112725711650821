import React from 'react';

const Services = () => {
  return (
    <div>
      <h1>What We Offer</h1>
      <ul>
        <li>RPA Services</li>
        <li>AI/ML Solutions</li>
        <li>GenAI Products</li>
      </ul>
    </div>
  );
};

export default Services;
